@charset "UTF-8";
a,
button,
input[type=submit],
input[type=button] {
  transition: all 0.5s ease;
}

a:focus,
button:focus,
input[type=submit]:focus,
input[type=button]:focus {
  outline: none;
}

body {
  background: #fff;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
}

.all {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.xxx {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

img {
  max-width: 100%;
  height: auto;
}

input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=url],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.flex {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.flex > [class*=col-] {
  display: inline-flex;
  flex-direction: column;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters [class^=col-],
.row.no-gutters [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.btn {
  display: inline-block;
  border: 0;
  outline: none;
  border: 0;
  padding: 0;
}

.btn-default {
  border-radius: 3px;
  background-color: #30c468;
  color: #ffffff;
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 0;
  outline: none;
  border: 0;
  padding: 0;
}

.btn-default:hover,
.btn-default:active,
.btn-default:focus {
  background: #4CAF50;
  color: #fff;
  border: 0;
  outline: none;
}

.body_menu {
  overflow-y: hidden;
}

ul,
li {
  list-style-type: none;
}

ul {
  padding: 0;
  margin: 0;
}

@media (max-width: 1720px) {
  .top_menu nav ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .top_menu nav ul li {
    padding-right: 0;
    display: block;
    padding: 5px 0;
  }

  .top_menu nav ul li a {
    color: #fff;
  }

  .top_menu nav ul li a:hover {
    text-decoration: underline;
  }

  header nav {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    display: none;
    position: fixed;
    top: 0;
    background: #19273a;
    left: 0;
    height: 100%;
    z-index: 33;
    width: 100%;
    right: 0;
    transition: all 0.5s;
    opacity: 0;
  }

  header.open_menu nav {
    right: 0;
    display: block;
    transition: all 0.5s;
    opacity: 1;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  header .toggle_mobile_btn {
    display: block;
    width: 33px;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    height: 20px;
    z-index: 44;
  }

  header .toggle_mobile_btn.on span:first-child {
    background-color: transparent;
  }

  header .toggle_mobile_btn.on span:nth-child(2) {
    transform: rotate(45deg) translate(-1px, 0);
    width: calc(100% - 3px);
    background: #fff;
  }

  header .toggle_mobile_btn.on span:last-child {
    transform: rotate(-45deg) translate(6px, -7px);
    width: calc(100% - 3px);
    background: #fff;
  }

  header .toggle_mobile_btn.on span:after {
    opacity: 0;
    height: 0;
  }

  header .toggle_mobile_btn span {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: calc(100% - 8px);
    height: 3px;
    background-color: #19273a;
    transition: all 0.3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  header .toggle_mobile_btn span:first-child {
    top: 0;
  }

  header .toggle_mobile_btn span:last-child {
    top: 16px;
    position: relative;
    display: block;
  }

  header .toggle_mobile_btn span:after {
    content: "";
    height: 3px;
    background-color: #19273a;
    position: absolute;
    right: -5px;
    width: 5px;
    transition: all 0.3s;
  }
}
/* Slider */
.slick-slider {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease;
}

.slick-slider.slick-initialized {
  visibility: visible;
  opacity: 1;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-slide:focus {
  outline: none;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

.slick-prev:before {
  content: "←";
}

.slick-next {
  right: -25px;
}

.slick-next:before {
  content: "→";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.container-faq {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.container-faq .item {
  background: red;
  height: 100px;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(25% - 30px);
}

@media screen and (max-width: 960px) {
  .container-faq .item {
    width: calc(50% - 30px);
  }
}
@media screen and (max-width: 560px) {
  .container-faq .item {
    width: calc(100% - 30px);
  }
}
@supports (-webkit-appearance:none){
.slick-loading .slick-list{background:#fff url("./ajax-loader.gif") center center no-repeat}
}

 @media (max-width: 1720px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 960px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (max-width: 560px){

@supports (-webkit-appearance:none){

}

}
